export default [

  {
    title: '   إدارة المعلومات ',
    icon: 'DatabaseIcon',

    children: [
      // {
      //   title: '   إدارة الموقع',
      //   icon: 'DatabaseIcon',
      // route: 'manageSite',
      // resource: 'modification_requests',
      // action: 'read',
      // },

      {
        title: 'نقاط الضعف',
        icon: 'FileIcon',
        route: 'add-weakpoint',
        resource: 'weaknesses',
        action: 'read',
      },
      {
        title: '   طلبات التعديل',
        icon: 'UserIcon',
        route: 'modification-requests',
        resource: 'modification_requests',
        action: 'read',
      },
      {
        title: '   طلبات الإضافة',
        icon: 'UserIcon',
        route: 'additional-requests',
        resource: 'modification_requests',
        action: 'read',
      },
      {
        title: 'الوثائق',
        icon: 'FileIcon',
        route: 'add-document',
        resource: 'all',
        action: 'manage',
      },
      
      {
        title: 'حالات الافراد',
        icon: 'FileIcon',
        route: 'add-person-status',
        resource: 'all',
        action: 'manage',
      },
      {
        title: '   المواقع',
        icon: 'FileIcon',
        route: 'CountryList',
        resource: 'all',
        action: 'manage',
      },
      {
        title: '   إضافة موقع',
        icon: 'FileIcon',
        route: 'add-location',
        resource: 'all',
        action: 'manage',
      },
      {
        title: '  الاقسام',
        icon: 'FileIcon',
        route: 'department',
        resource: 'all',
        action: 'manage',
      },
      {
        title: 'إضافة خدمة',
        icon: 'FileIcon',
        route: 'add-service',
        resource: 'all',
        action: 'manage',
      },
      {
        title: '   الاعمال',
        icon: 'FileIcon',
        route: 'add-work',
        resource: 'works',
        action: 'read',
      },
    ],
  },
  {
    title: ' الموارد البشرية',
    icon: 'DatabaseIcon',
    children: [

      {
        title: ' طلبات التوظيف',
        icon: 'UserIcon',
        route: 'apps-candidates-list',
        resource: 'candidates',
        action: 'read',
      },
      {
        title: ' الحضور ',
        icon: 'UserIcon',
        route: 'apps-users-attendence-list',
        resource: 'user',
        action: 'read',
      },
      {
        title: ' التعميمات',
        icon: 'FileIcon',
        route: 'add-inform',
        resource: 'inform',
        action: 'all',
      },
      {
        title: ' الاجازات',
        icon: 'CalendarIcon',
        route: 'leaves',
        resource: 'leaves',
        action: 'all',
      },
      {
        title: ' انواع الاجازات',
        icon: 'CalendarIcon',
        route: 'leaves-type',
        resource: 'leaves_type',
        action: 'all',
      },
      {
        title: ' المناصب',
        icon: 'FileIcon',
        route: 'position',
        resource: 'position',
        action: 'all',
      },
      {
        title: ' العطل',
        icon: 'CalendarIcon',
        route: 'holidays',
        resource: 'holidays',
        action: 'all',
      },
      {
        title: ' الرواتب',
        icon: 'FileIcon',
        route: 'salary',
        resource: 'salaries',
        action: 'all',
      },
      {
        title: ' المكافأت',
        icon: 'FileIcon',
        route: 'reward',
        resource: 'rewards',
        action: 'all',
      },
      {
        title: ' مكافأت الموظفين',
        icon: 'FileIcon',
        route: 'user-reward',
        resource: 'rewards',
        action: 'all',
      },

      {
        title: 'الدفعات الشهرية ',
        icon: 'FileIcon',
        route: 'user-payment',
        resource: 'user_payment',
        action: 'all',
      },
      {
        title: ' العهد',
        icon: 'FileIcon',
        route: 'pacts',
        resource: 'pacts',
        action: 'all',
      },

      {
        title: '  الاهداف',
        icon: 'FileIcon',
        route: 'achievement_targets',
        resource: 'goals',
        action: 'all',
      }],
  },
  {
    title: 'المالية',
    icon: 'DatabaseIcon',

    children: [
    
      {
        title: 'استكمال معلومات المانح',
        icon: 'FileTextIcon',
        route: 'complete-procurment-order',
        resource: 'complete_procurment_order',
        action: 'all',

      },

      {
        title: '    موافقات امر الصرف',
        icon: 'FileTextIcon',
        route: 'payment-order-finance',
        resource: 'approve_exchange_order',
        action: 'all',

      },
      {
        title: '   امر الصرف',
        icon: 'FileIcon',
        route: 'create-payment-order',
        resource: 'payment_order',
        action: 'all',
      },
      {
        title: '  سندات القيود  ',
        icon: 'FileIcon',
        route: 'create-report-de',
        resource: 'journal_transaction',
        action: 'all',
      },
      {
        title: '  موافقات سندات القيود  ',
        icon: 'FileIcon',
        route: 'pending-transaction',
        resource: 'pending_transaction_archive',
        action: 'all',
      },
      {
        title: 'دفتر الاستاذ',
        icon: 'FileTextIcon',
        route: 'transactions-archive',
        resource: 'transactions_archive',
        action: 'all',

      },
      {
        title: 'العملات',
        icon: 'FileTextIcon',
        route: 'currency',
        resource: 'currency',
        action: 'all',

      },
      {
        title: 'دليل الحسابات',
        icon: 'FileTextIcon',
        route: 'finance-accounts',
        resource: 'finance_accounts',
        action: 'all',

      },
      {
        title: 'سعر الصرف',
        icon: 'FileTextIcon',
        route: 'exchange-rate',
        resource: 'exchange_rate',
        action: 'all',

      },
      {
        title: 'المانح',
        icon: 'FileTextIcon',
        children: [{
          title: 'قائمة المانحين',
          icon: 'FileTextIcon',
          route: 'donor',
          resource: 'donor',
          action: 'all',
        },
        {
          title: 'Biller',
          icon: 'FileTextIcon',
          route: 'donor-biller',
          resource: 'donor',
          action: 'all',

        },
        {
          title: 'OutPut',
          icon: 'FileTextIcon',
          route: 'income',
          resource: 'out_put',
          action: 'all',

        },
        {
          title: 'OutCome',
          icon: 'FileTextIcon',
          route: 'outcome',
          resource: 'out_come',
          action: 'all',

        },

        {
          title: 'الحسابات',
          icon: 'FileTextIcon',
          route: 'donor-account',
          resource: 'donor_account',
          action: 'all',
        },
        {
          title: '   الحسابات الفرعية',
          icon: 'FileTextIcon',
          route: 'donor-sub-account',
          resource: 'donor_sub_account',
          action: 'all',
        },

        ],

      },
    ],
  },
  {
    title: 'المستخدمون',
    icon: 'UserIcon',
    children: [{
      title: 'كل المستخدمون',
      icon: 'UserIcon',
      route: 'apps-users-list',
      resource: 'users',
      action: 'read',
    },

    ],

  },
  {
    title: '    طلبات الحاجة  ',
    icon: 'FileIcon',
    children: [
      {
        title: '  طلب حاجة',
        icon: 'FileIcon',
        route: 'request-item',
        resource: 'items_order',
        action: 'all',
      },

      {
        title: ' قائمة طلبات الاحتياج   ',
        icon: 'FileIcon',
        route: 'request-ItemList',
        resource: 'approve_items_order',
        action: 'all',
      },
      
      {
        title: '  طلبات الاحتياج المنتظرة   ',
        icon: 'FileIcon',
        route: 'rejected-ItemList',
        resource: 'rejected_item',
        action: 'all',
      },
    ]},
  {
    title: 'المخازن و المستودعات',
    icon: 'DatabaseIcon',
    children: [
     
     
    
      {
        title: ' الجرد ',
        icon: 'DatabaseIcon',
        children: [
          {
            title: '   تفاصيل المادة',
            icon: 'FileIcon',
            route: 'item-details',
            resource: 'item_details',
            action: 'all',
          }, {
            title: '    جرد مستودع',
            icon: 'FileIcon',
            route: 'warehouse-details',
            resource: 'warehouse_details',
            action: 'all',
          },
          {
            title: 'إشعار انتهاء الصلاحية  ',
            icon: 'FileTextIcon',
            route: 'item-expiration-date',
            resource: 'item_expiration_date',
            action: 'all',
    
          },
        
        ]},
      {
        title: '  بطاقة مجموعة المواد',
        icon: 'FileIcon',
        route: 'item-category',
        resource: 'item_category',
        action: 'all',
      },
      {
        title: '   بطاقة مادة',
        icon: 'FileIcon',
        route: 'item',
        resource: 'item',
        action: 'all',
      },

   
    
      // {
      //   title: '    اخراج مادة ',
      //   icon: 'FileIcon',
      //   route: 'export-item',
      //   resource: 'export-item',
      //   action: 'all',
      // },
      // {
      //   title: '    ادخال مادة ',
      //   icon: 'FileIcon',
      //   route: 'enter-item',
      //   resource: 'enter_item',
      //   action: 'all',
      // },
      {
        title: 'المستودعات',
        icon: 'FileTextIcon',
        route: 'warehouse',
        resource: 'warehouse',
        action: 'all',

      },

      {
        title: 'الواحدات',
        icon: 'FileTextIcon',
        route: 'unit',
        resource: 'unit',
        action: 'all',

      },
     
      {
        title: '    حركات المستودع ',
        icon: 'FileIcon',
        children: [
          {
            title: '       ادخال مستودع ',
            icon: 'FileIcon',
            route: 'enter-warehouse',
            resource: 'warehouse_transactions',
            action: 'all',
          },
          {
            title: ' أرشيف حركات المستودع  ',
            icon: 'FileIcon',
            route: 'transaction-archive',
            resource: 'warehouse_transactions',
            action: 'all',
          },
          {
            title: '       اخراج مستودع ',
            icon: 'FileIcon',
            route: 'withdrawal-warehouse',
            resource: 'warehouse_transactions',
            action: 'all',
          },
          {
            title: '        بطاقة مرتجع ',
            icon: 'FileIcon',
            route: 'return-note-warehouse',
            resource: 'warehouse_transactions',
            action: 'all',
          },{
            title: '        بطاقة اتلاف ',
            icon: 'FileIcon',
            route: 'disposal-note-warehouse',
            resource: 'warehouse_transactions',
            action: 'all',
          },{
            title: 'بضاعة أول المدة',
            icon: 'FileIcon',
            route: 'first-enter-warehouse',
            resource: 'warehouse_transactions',
            action: 'all',
          },
          {
            title: '     بطاقة مناقلة',
            icon: 'FileIcon',
            route: 'new-release-order',
            resource: 'warehouse_transactions',
            action: 'all',
          },
        ]
        
      
      },
      // {
      //   title: '     تقرير الاستلام ',
      //   icon: 'FileIcon',
      //   route: 'item-details',
      //   resource: 'receipt_report-all',
      //   action: 'all',
      // },
      

    ],

  },
  {
    title: '  إدارة العقود  ',
    icon: 'FileIcon',
    children: [
      {
        title: 'الموردين',
        icon: 'FileTextIcon',
        route: 'vendor',
        resource: 'vendor',
        action: 'all',

      },
      // {
      //   title: '  عروض الاسعار',
      //   icon: 'FileIcon',
      //   route: 'request-price-offer',
      //   resource: 'upload_price_offers-all',
      //   action: 'all',
      // },
      // {
      //   title: '   ارفع فاتورة',
      //   icon: 'FileIcon',
      //   route: 'upload-invoice',
      //   resource: 'upload_bill-all',
      //   action: 'all',
      // },

      // {
      //   title: '    حركات المستودع ',
      //   icon: 'FileIcon',
      //   route: 'request-purchase-order',
      //   resource: 'warehouse_transactions',
      //   action: 'all',
      // },
     
      {
        title: '     انشاء طلب شراء ',
        icon: 'FileIcon',
        route: 'create-new-purchase-order',
        resource: 'warehouse_transactions',
        action: 'all',
      },
     
      // {
      //   title: '  استكمال طلبات الشراء',
      //   icon: 'FileTextIcon',
      //   route: 'complete-purches-order',
      //   resource: 'complete_purches_order',
      //   action: 'all',

      // },
    
      {
        title: 'اوامر الشراء',
        icon: 'FileTextIcon',
        route: 'purchase-order',
        resource: 'purchase_order',
        action: 'all',

      },
      {
        title: 'عقد الشراء',
        icon: 'FileTextIcon',
        route: 'purchase-contract',
        resource: 'purchase_contract',
        action: 'all',

      },
      {
        title: 'عروض الأسعار',
        icon: 'FileTextIcon',
        route: 'price-offer',
        resource: 'upload_price_offers',
        action: 'all',

      },
      {
        title: ' فاتورة الشراء',
        icon: 'FileTextIcon',
        route: 'purchase-bill',
        resource: 'upload_bill',
        action: 'all',

      },
    ],

  },
  {
    title: 'الموافقات',
    icon: 'DatabaseIcon',
    children: [{
      title: '  طلبات الشراء',
      icon: 'FileIcon',
      route: 'purches-request-approve',
      resource: 'in_transaction',
      action: 'all',
    },
    {
      title: '    موافقات حركات المستودع',
      icon: 'FileTextIcon',
      route: 'complete-out-transaction',
      resource: 'complete_out_transaction',
      action: 'all',

    },
    {
      title: '  متابعة طلبات الشراء',
      icon: 'FileIcon',
      route: 'follow-up-purchase-request',
      resource: 'follow_up_purchase_request',
      action: 'all',
    },
 
    {
      title: '  اوامر الشراء',
      icon: 'FileIcon',
      route: 'purches-order-approve',
      resource: 'purchase_order',
      action: 'all',
    },
    {
      title: '  اوامر الصرف',
      icon: 'FileIcon',
      route: 'payment-order',
      resource: 'prepare_exchange_order',
      action: 'all',
    },
    {
      title: '   تقارير الاستلام',
      icon: 'FileIcon',
      route: 'delivery-reports',
      resource: 'receipt_report',
      action: 'all',
    }],
  },
    // POLICES
    {
      title: 'السياسات',
      icon: 'DatabaseIcon',
      children: [
      
      {
        title: ' إضافة سياسة  ',
        icon: 'FileIcon',
        route: 'add-policy',
        resource: 'policy',
        action: 'create',
      },
      {
        title: ' السياسات ',
        icon: 'FileIcon',
        route: 'all-policies',
        resource: 'policy',
        action: 'all',
      },
  
      ],
    },

  // SESSIONS 
  {
    title: 'الجلسات',
    children: [
    
    {
      title: ' اضافة جلسة ',
      icon: 'UserIcon',
      route: 'add-session',
      resource: 'session_mobile_app',
      action: 'all',
    },
    {
      title: ' الجلسات ',
      icon: 'UserIcon',
      route: 'all-session',
      resource: 'session_mobile_app',
      action: 'all',
    },

    ],
  },

  {
    title: ' متطوعي الوصول',
    icon: 'UserIcon',
    children: [{
      title: '  استمارة منزلية جديدة',
      icon: 'UserIcon',
      route: 'store-visit',
      resource: 'store_visit',
      action: 'all',
    },
    {
      title: ' المتطوعين ',
      icon: 'UserIcon',
      route: 'volunteer',
      resource: 'store_visit',
      action: 'all',
    },
    {
      title: '  الطلبات',
      icon: 'UserIcon',
      route: 'orv-additional-request',
      resource: 'show_request_interface',
      action: 'all',
    },
   
    {
      title: 'الاحصائيات',
      icon: 'UserIcon',
      route: 'orv-stats',
      resource: 'store_visit',
      action: 'all',
    },
    {

      title: ' تسجيل زيارة',
      icon: 'UserIcon',
      route: 'store-family-visit',
      resource: 'store_visit',
      action: 'all',
    },
    
    {
      title: '  الزيارات',
      icon: 'FileIcon',
      route: 'list-visit',
      resource: 'store_visit',
      action: 'all',
    },

    ],

  },

  {
    title: 'العائلات',
    icon: 'UserIcon',

    route: 'all-families',

    children: [{
      title: 'كل العائلات',
      icon: 'UserIcon',
      route: 'all-families',
      resource: 'beneficiaries',
      action: 'read',
    },
    {
      title: ' المواعيد',
      icon: 'UserIcon',
      route: 'add-appointment',
      resource: 'beneficiaries',
      action: 'read',
    },

 
    {
      title: 'كل الافراد',
      icon: 'UserIcon',
      route: 'all-benificary',
      resource: 'all_benificary',
      action: 'all',
    },
    {
      title: '   العائلات غير المستكملة',
      icon: 'UserIcon',
      route: 'un-completed-families',
      resource: 'beneficiaries',
      action: 'read',
    },
    {
      title: '  إضافة مستفيد',
      icon: 'UserIcon',
      route: 'add-beneficiary',
      resource: 'beneficiaries',
      action: 'read',
    },
    ],
    // },
  },
  // {
  //   title: ' السياسات  ',
  //  icon: 'FileIcon',
  // route: 'add-policy',
  // resource: 'policy',
  // action: 'create',
  //       },

  {
    title: 'طلبات التكليف',
    icon: 'FileTextIcon',
    route: 'leaves-approve',
    action: 'read',
    resource: 'all',

  },
  {
    title: 'موافقات الاجازات ',
    icon: 'FileTextIcon',
    route: 'leaves-approve-coordinator',
    resource: 'leaves_approve_coordinator',
    action: 'read',

  },
  
  {
    title: '  المراجعات/ الشكاوى',
    icon: 'FileTextIcon',
    route: 'problems-list',
    resource: 'complaints',
    action: 'all',

  },
  {
    title: 'التحويلات الداخلية',
    icon: 'FileTextIcon',
    route: 'transfer-list',
    resource: 'services',
    action: 'read',

  },
  // {
  //   title: '  طلبات بدء الحالة',
  //   icon: 'FileTextIcon',
  //   route: 'request-auditing',
  //   resource: 'services',
  //   action: 'read',

  // },
  {
    title: '  طلبات الحالة',
    icon: 'FileTextIcon',
    route: 'case-auditing',
    resource: 'services',
    action: 'read',

  },
  {
    title: 'الخدمات',
    icon: 'FileTextIcon',
    children: [{
      title: '  خدمات الافراد',
      icon: 'FileTextIcon',
      route: 'service-list',
      resource: 'services',
      action: 'read',

    },

    {
      title: '  خدمات العائلة',
      icon: 'FileTextIcon',
      route: 'services-families-list',
      resource: 'services',
      action: 'read',

    }],
  },


  {
    title: '  احصائيات',
    icon: 'FileTextIcon',

    children: [{

      title: '  احصائيات عامة',
      icon: 'FileTextIcon',
      route: 'dashboard-statistics',

      resource: 'statistics',
      action: 'read',

    },
    {

      title: '  احصائيات الانجاز',
      icon: 'FileTextIcon',
      route: 'achievement_stats',

      resource: 'statistics',
      action: 'read',

    },
    {
      title: '  احصائيات متقدمة',
      icon: 'FileTextIcon',
      route: 'beneficiaries-list',
      resource: 'statistics',
      action: 'read',

    },
    {
      title: '  احصائيات فريق التسجيل',
      icon: 'FileTextIcon',
      route: 'registering-statistics',
      resource: 'registration_statistics',
      action: 'all',
  
    },
  ],

  },
  
  {
    title: '  صلاحيات الوصول',
    icon: 'FileTextIcon',
    route: 'all-roles',
    resource: 'roles',
    action: 'read',

  },
  {
    title: '  تقرير الخدمات  ',
    route: 'generate-service-report',
    icon: 'PlusIcon',
  },
  {
    title: '  تقرير نقاط الضعف',
    route: 'generate-report',
    icon: 'PlusIcon',
  },

  // {
  //   title: 'استمارة حالات الطوارئ',
  //   route: 'emergency',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'استمارة التقييم',

  //   route: 'Assessment-Form',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'استبيان تقييم احتياج سريع',
  //   route: 'evaluation-question',
  //   icon: 'FileTextIcon',
  // },
  //   {
  //     title: 'الإجراءالخاص بالناجي ',

  //     route: 'Survivor-plan',
  //     icon: 'FileTextIcon',
  //   },

  // {
  //   title: ' ورقة الرصد ',

  //   route: 'monitoring-paper',
  //   icon: 'FileTextIcon',
  // },
  //   {
  //     title: ' الإحالة',

  //     route: 'Referral-Form',
  //     icon: 'FileTextIcon',
  //   },

  // {
  //   title: ' متابعة الحالة',
  //   route: 'Case-follow-up',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: ' إغلاق الحالة',
  //   route: 'case-close-up',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'الموافقة على نشر المعلومات',
  //   route: 'Consent-Form',
  //   icon: 'FileTextIcon',
  // },

  // {
  //   title: ' إحالة طبية',

  //   route: 'medical-referral',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'برنامج الدعم النفسي ',

  //   route: 'psychosocial-support',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: ' البرنامج التعليمي ',

  //   route: 'educationl-programs',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'المساعدات العينية',

  //   route: 'Assessment-aid',
  //   icon: 'FileTextIcon',
  // },
  // {
  //   title: 'Email',
  //   route: 'apps-email',
  //   icon: 'MailIcon',
  // },
  // {
  //   title: 'Chat',
  //   route: 'apps-chat',
  //   icon: 'MessageSquareIcon',
  // },
  // {
  //   title: 'Todo',
  //   route: 'apps-todo',
  //   icon: 'CheckSquareIcon',
  // },
  // {
  //   title: 'Calendar',
  //   route: 'apps-calendar',
  //   icon: 'CalendarIcon',
  // },

  // {
  //   title: 'Invoice',
  //   icon: 'FileTextIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-invoice-list',
  //     },
  //     {
  //       title: 'Preview',
  //       route: { name: 'apps-invoice-preview', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Add',
  //       route: { name: 'apps-invoice-add' },
  //     },
  //   ],
  // },
  // {
  //   title: 'eCommerce',
  //   icon: 'ShoppingCartIcon',
  //   children: [
  //     {
  //       title: 'Shop',
  //       route: 'apps-e-commerce-shop',
  //     },
  //     {
  //       title: 'Details',
  //       route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
  //     },
  //     {
  //       title: 'Wishlist',
  //       route: 'apps-e-commerce-wishlist',
  //     },
  //     {
  //       title: 'Checkout',
  //       route: 'apps-e-commerce-checkout',
  //     },
  //   ],
  // },
  // {
  //   title: 'User',
  //   icon: 'UserIcon',
  //   children: [
  //     {
  //       title: 'List',
  //       route: 'apps-users-list',
  //     },
  //     {
  //       title: 'View',
  //       route: { name: 'apps-users-view', params: { id: 21 } },
  //     },
  //     {
  //       title: 'Edit',
  //       route: { name: 'apps-users-edit', params: { id: 21 } },
  //     },
  //   ],
  // },
  // {
  //   title: 'Pages',
  //   icon: 'FileIcon',
  //   children: [
  //     {
  //       title: 'Authentication',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Login v1',
  //           route: 'auth-login-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Login v2',
  //           route: 'auth-login-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v1',
  //           route: 'auth-register-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v2',
  //           route: 'auth-register-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v1',
  //           route: 'auth-forgot-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v2',
  //           route: 'auth-forgot-password-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v1',
  //           route: 'auth-reset-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v2',
  //           route: 'auth-reset-password-v2',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Account Settings',
  //       route: 'pages-account-setting',
  //     },
  //     {
  //       title: 'Profile',
  //       route: 'pages-profile',
  //     },
  //     {
  //       title: 'Faq',
  //       route: 'pages-faq',
  //     },
  //     {
  //       title: 'Knowledge Base',
  //       route: 'pages-knowledge-base',
  //     },
  //     {
  //       title: 'Pricing',
  //       route: 'pages-pricing',
  //     },
  //     {
  //       title: 'Blog',
  //       children: [
  //         {
  //           title: 'List',
  //           route: 'pages-blog-list',
  //         },
  //         {
  //           title: 'Detail',
  //           route: { name: 'pages-blog-detail', params: { id: 1 } },
  //         },
  //         {
  //           title: 'Edit',
  //           route: { name: 'pages-blog-edit', params: { id: 1 } },
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Mail Templates',
  //       children: [
  //         {
  //           title: 'Welcome',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
  //         },
  //         {
  //           title: 'Reset Password',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
  //         },
  //         {
  //           title: 'Verify Email',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
  //         },
  //         {
  //           title: 'Deactivate Account',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
  //         },
  //         {
  //           title: 'Invoice',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
  //         },
  //         {
  //           title: 'Promotional',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Miscellaneous',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Coming Soon',
  //           route: 'misc-coming-soon',
  //           target: '_blank',
  //         },
  // {
  //   title: 'Not Authorized',
  //   route: 'misc-not-authorized',
  //   target: '_blank',
  // },
  //         {
  //           title: 'Under Maintenance',
  //           route: 'misc-under-maintenance',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Error',
  //           route: 'misc-error',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //   ],
  // },
]
