<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          الاشعارات
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ notifications.length }} جديدة
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar

      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >

      <!-- Account Notification -->
      <b-link
      
        v-for="(notification,index) in notifications"
        :key="index"
     
   
      >

        <b-media>
        
          <template #aside>
            
            <b-avatar
              size="32"

              variant="light-info"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
             <router-link :to="`${notification.root}`">
              {{ notification.text }}

            </router-link >
            </span>
          </p>
        
          <!-- <small class="notification-text">{{ notification.updated_at }}</small> -->
     
        </b-media>
      </b-link>
     
      <label>
        الاشعارات السابقة
    
      </label>
      <b-link
        v-for="(notification,index) in oldnotifications"
        :key="'A'+index"
      >

        <b-media>
          <template #aside>
            <b-avatar
              size="32"

              variant="light-info"
            />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              <router-link :to="`${notification.root}`">
              {{ notification.text }}

            </router-link >
            </span>
          </p>
          <small class="notification-text">{{ notification.updated_at }}</small>
       

        </b-media>
      </b-link>
      <!-- System Notification Toggler -->

      <!-- System Notifications -->

    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="readNotification"
    >تم قراءة الاشعارات  </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BDropdownItem,
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import financeStoreModule from '@/views/apps/finance/financeStoreModule'

export default {
  components: {
    financeStoreModule,
    BDropdownItem,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = ref([

    ])
    const oldnotifications = ref([

    ])
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })
    store.dispatch('app-finance/GetAllNotifications').then(response => {
      //console.log('oldnotifications,', response)
    
      oldnotifications.value = []
        response.data.forEach(el => {
       
        oldnotifications.value.push({
            text: el.data.body.text,
            updated_at: el.updated_at,
            root: el.data.body.root,
            id: el.id,

          })
        })
    })
    const readNotification = () => {
      store.dispatch('app-finance/readNotification').then(response => {
        //console.log('oldnotifications,', response)
      })
    }
    setInterval(() => {
      store.dispatch('app-finance/GetNotifications').then(response => {
        //console.log('GetNotifications,', response.data[0].data.body.text)
        notifications.value = []
        response.data.forEach(el => {
       
          notifications.value.push({
            text: el.data.body.text,
            updated_at: el.updated_at,
            root: el.data.body.root,
            id: el.id,

          })
      })
        // notifications.value = response.data
        //console.log('notificationsnotifications,', notifications)
      })
    }, 30000)

    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon',
      },
      {
        title: 'Sales report generated',
        subtitle: 'Last month sales report generated',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      readNotification,
      oldnotifications,
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
  created() {

  },
  methods: {

    getNotification() {
      this.$http.get('/api/v1/get-user-notifications').then(res => {
        //console.log('get-user-notifications', res.data)
        this.notifications = res.data
        //console.log('get-user-   this.notifications', this.notifications)
      })
    },

    //
  },
}
</script>

<style>

</style>
